a {
  color: inherit;
  text-decoration: inherit;
}

* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
  margin-block-start: 0;
  margin-block-start: 0;
  letter-spacing: 0px;
  text-rendering: optimizeLegibility;
  &:after, &:before {
    border-width: 0;
    border-style: solid;
  }
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

div {
  letter-spacing: 0px;
  line-height: 20px;
  text-rendering: optimizeLegibility;
}

table, tr {
  border-collapse: collapse;
}

button {
  background-color: inherit;
  outline: none;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  line-height: inherit;
}

input {
  box-sizing: border-box;
  border: none;
  outline: none;
  line-height: inherit;
}

svg {
  vertical-align: middle;
  display: block;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
