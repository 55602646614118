@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-Light.woff2") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-LightItalic.woff2") format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-Regular.woff2") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-Italic.woff2") format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-SemiBold.woff2") format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-Medium.woff2") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-SemiBoldItalic.woff2") format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-Bold.woff2") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/fonts/Montserrat/Montserrat-ExtraBold.woff2") format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
