$mobile_header_breakpoint: 1279px;

:root {
  --mobile-header-breakpoint: #{$mobile_header_breakpoint};
  --form-vertical-gap: 12px;
  --form-horizontal-gap: 20px;

  --navbar-height: 50px;
  --checkout-tabs-vertical-padding: 6px;

  @media screen and (max-width: $mobile_header_breakpoint) {
    --navbar-height: 40px;
    --checkout-tabs-vertical-padding: 0;
  }
}