@import "./keyframes.scss";
@import "./hamburger.scss";
@import "./fonts.scss";
@import "./transitions.scss";
@import "./variables.scss";
@import "./reset.scss";
@import "~normalize.css/normalize.css";

*:before, *:after, * {
  box-sizing: border-box;
}

html, body {
  font-size: 14px;
  line-height: 23px;
  font-family: "Montserrat", serif;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 23px;
  }
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

body.modal-open {
  overflow-y: hidden;
} 

.__react_component_tooltip {
  box-shadow: 0 2px 8px rgba(0,0,0,.15) !important;
}

//Default button text color for prevent blue text on iOS15
button {
  color: #000000;
}

h1 {
  font-size: 2.42rem; // 34px
  line-height: 3.21rem; // 44px
  @media screen and (max-width: 960px) {
    font-size: 1.57rem; // ~22px
    line-height: 2.28rem; // ~32px
  }
}

h2 {
  font-size: 1.85rem; // 26px
  line-height: 2.57rem; // 36px
  @media screen and (max-width: 960px) {
    font-size: 1.42rem; // ~20px
    line-height: 2rem; // ~28px
  }
}

h3 {
  font-size: 1.71rem; // 24px
  line-height: 2.42rem; // 34px
  @media screen and (max-width: 960px) {
    font-size: 1.28rem; // 18px
    line-height: 2rem; // ~28px
  }
}

h4 {
  font-size: 1.42rem; // 20px
  line-height: 2.14rem; // 30px
  @media screen and (max-width: 960px) {
    font-size: 1.14rem; // 16px
    line-height: 1.85rem; // ~26px
  }
}

h5 {
  font-size: 1.14rem; // 16px
  line-height: 1.85rem; // 26px
  @media screen and (max-width: 960px) {
    font-size: 1rem; // 14px
    line-height: 1.64rem; // ~23px
  }
}
body {
  /** 
   * we keep body scroll unstyled (native)
   * but we add these styles to all the children
   * as decided in LM-676 
   */
   --scrollbar-width: 7px;

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }
  ::-webkit-scrollbar-track {
    box-shadow: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    outline: 1px solid white;
  }
}